import Login from "./screens/Login_screen";
import Dashboard from "./screens/dashboard/Dashboard";
import ErrorScreen from "./screens/Error_screen";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import React, { useContext } from "react";
import { AuthContext } from "./states/AuthContext";
import FurnitureList from "./screens/furniture/list/FurnitureList";
import AddFurniture from "./screens/furniture/add/AddFurniture";
import EditFurniture from "./screens/furniture/edit/EditFurniture";

function App() {
  const { loggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={loggedIn ? <Navigate to="/furniturelist" /> : <Login />} />
      <Route path="/furniturelist" element={loggedIn ? <FurnitureList /> : <Navigate to="/" />} />
      <Route path="/addfurniture" element={loggedIn ? <AddFurniture /> : <Navigate to="/" />} />
      <Route path="/editfurniture" element={loggedIn ? <EditFurniture /> : <Navigate to="/" />} />
      <Route path="*" element={<ErrorScreen />} />
    </Routes>
  );
}

export default App;

import React from "react";
import { NavLink, useLocation } from "react-router-dom";
export default function Sidebar() {
  const location = useLocation();
  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div className="dropdown profile-element left-profile">
              {/* <Link to="/home"> */}
              <img
                className="img-fluid"
                src="/asset/logo/logo-light.png"
                alt="logo"
                style={{
                  padding: '10px',
                  background: '#fff',
                  borderRadius: '10px'
                }}
              />

              {/* <NavLink to="/users-list">
                <h3 className="block font-bold font-italic text-center text-white">
                  TELLMA
                </h3>
              </NavLink> */}
            </div>
            <div className="logo-element"></div>
          </li>
          <li>
            <NavLink to="/furniturelist"
              className={location.pathname === "/furniturelist" || location.pathname === "/addfurniture" || location.pathname === "/editfurniture" ? "active" : ""}>
              <i className="bi bi-box"></i>
              <span className="nav-label">Furniture</span>
            </NavLink>
          </li>

        </ul>
      </div>
    </nav >
  );
}

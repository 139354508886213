import React from "react";
import LogInBackground from "../images/bgimage.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../services/Api_calling";
import { useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { AuthContext } from "../states/AuthContext";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const emailRef = useRef();
  const pwdRef = useRef();
  const { setLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      emailRef.current.value.toString().trim() === "" ||
      pwdRef.current.value.toString().trim() === ""
    ) {
      toast.error("All fields are required!");
      return;
    }
    setLoading(true);
    const data = {
      email: emailRef.current.value,
      password: pwdRef.current.value,
    };
    const res = await login(data);
    if (res.success) {
      setLoggedIn(true);
      localStorage.setItem("loggedIn", "true");
      localStorage.setItem("authToken", `${res.data.token}`);
      toast.success("Logged in successfully");
      navigate("/furniturelist", { replace: true });
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ToastContainer />
      <section
        className="vh-100"
        style={{
          height: "100vh",
          backgroundImage: `url(${LogInBackground})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5 text-center">
                  <h3 className="mb-5">
                    <img
                      className="img-fluid w-50"
                      src="/asset/logo/logo-light.png"
                      alt="logo"
                    />
                  </h3>
                  <form action="" onSubmit={onSubmitHandler}>
                    <div className="form-outline mb-4 text-left">
                      <label className="form-label" htmlFor="typeEmailX-2">
                        Email<span style={{
                          color: 'red'
                        }}>*</span>
                      </label>
                      <input
                        ref={emailRef}
                        name="email"
                        id="email"
                        type="email"
                        className="form-control form-control-lg"
                        required={true}
                        placeholder="email"
                      />
                    </div>
                    <div className="form-outline mb-4 text-left">
                      <label className="form-label" htmlFor="typePasswordX-2">
                        Password<span style={{
                          color: 'red'
                        }}>*</span>
                      </label>
                      <div className="input-group" style={{ position: 'relative' }}>
                        <input
                          name="password"
                          id="password"
                          ref={pwdRef}
                          type={showPassword ? "text" : "password"} // Toggle type based on state
                          className="form-control form-control-lg"
                          required={true}
                          placeholder="Password"
                          style={{
                            paddingRight: '40px', // Add padding to prevent text from overlapping with the icon
                          }}
                        />
                        <span
                          onClick={toggleShowPassword}
                          style={{
                            position: 'absolute',
                            right: '10px', // Position the icon inside the input
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            zIndex: '999'

                          }}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>

                    </div>
                    {loading ? (
                      <Loader />
                    ) : (
                      <button
                        className="btn btn-primary btn-lg btn-block"
                        type="submit"
                      >
                        Login
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import { getFurnitures, deletFurniture } from "../../../services/Api_calling";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import Pagination from "../../../components/Pagination";

const FurnitureTable = () => {
  const [loading, setLoading] = useState(false);
  const [furnitureData, setFurnitureData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);
  const limit = 15;
  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    const res = await getFurnitures(currentPage, limit);
    if (res.success) {
      setFurnitureData(res.data);
      setTotalNumberOfPages(res.totalNumberOfPages);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  const deleteFurniture = async (id) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this file?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    });

    if (willDelete) {
      setLoading(true)
      const initialState = [...furnitureData];
      const updatebleState = initialState.filter((e) => e.id !== id);
      setFurnitureData(updatebleState);

      const res = await deletFurniture(id);

      if (res.success) {
        getData();
        swal("Deleted!", "Your Furniture has been deleted!", "success");
      } else {
        toast.error(res.message);
        setFurnitureData(initialState);
      }
      setLoading(false)
    }
  };


  const handleEditClick = (data) => {
    navigate("/editfurniture", { state: { data } })
  }

  return (
    <>
      <ToastContainer position="top-center" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <button
            className="btn btn-primary btn-sm mr-1 mb-2 mt-2 float-right"
            onClick={() => navigate("/addfurniture")}
          >
            Add Furniture
          </button>
          {furnitureData.length ? (
            <>
              <table
                id="dtBasicExample"
                className="table table-striped table-bordered table-sm mt-3 "
              >
                <thead>
                  <tr>
                    <th className="th-sm">SNO</th>
                    <th className="th-sm">FILE NAME</th>
                    <th className="th-sm">THUMBNAIL</th>
                    <th className="th-sm">MODEL</th>
                    <th className="th-sm">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {furnitureData.map((data, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * limit + index + 1}</td>
                      <td>{data?.file_name}</td>
                      <td>
                        <img
                          className="img-fluid ThumbnailImage"
                          src={data?.thumbnail}
                          alt=""
                        />
                      </td>
                      <td>
                        <Link className="" to={data?.model_file}>
                          <button className="btn btn-transparent" type="button"><i class="bi bi-cloud-download"></i> Download Model</button>
                        </Link>
                      </td>
                      <td>
                        <div
                          className="d-flex"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            gap: '10px'
                          }}
                        >
                          <button className="btn btn-outline-dark" onClick={() => handleEditClick(data)}>
                            <i class="bi bi-pencil mr-1"></i> Edit
                          </button>

                          <button className="btn btn-outline-danger" onClick={() => {
                            deleteFurniture(data.id);
                          }}>
                            <i class="bi bi-trash3 mr-1"></i>
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalNumberOfPages={totalNumberOfPages}
              />
            </>
          ) : (
            <h3 className="text-center">Data not found</h3>
          )}
        </>
      )}
    </>
  );
};

export default FurnitureTable;

import React, { useEffect, useState } from "react";
import { updateFurniture } from "../../../services/Api_calling";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";

const EditFurnitureForm = () => {
  const navigate = useNavigate();
  const [fbxFile, setFbxFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [preData, setPreData] = useState({});

  const maxFileSizeMb = 30; // Max size in MB

  useEffect(() => {
    setPreData(state.data);
    setFileName(state.data.file_name);
  }, [state]);


  const fbxFileHandler = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.name.endsWith(".fbx")) {
      const fileSizeMB = selectedFile.size / 1024 / 1024; // Convert bytes to MB
      if (fileSizeMB > maxFileSizeMb) {
        toast.error("File size should not exceed 30MB");
        setFbxFile(null);
        e.target.value = null; // Clear the input field
        return;
      }
      setFbxFile(selectedFile);
    } else {
      toast.error("Please select a valid .fbx file");
      setFbxFile(null);
      e.target.value = null; // Clear the input field
    }
  };

  const extractFileName = (url) => {
    const parts = url.split('/');
    const fileName = parts.pop();
    return fileName;
  }


  const fileNameHandler = (e) => {
    setFileName(e.target.value);
  }

  const thumbnailHandler = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type.startsWith("image")) {
      const fileSizeMB = selectedFile.size / 1024 / 1024; // Convert bytes to MB
      if (fileSizeMB > maxFileSizeMb) {
        toast.error("Thumbnail size should not exceed 30MB");
        setThumbnail(null);
        e.target.value = null; // Clear the input field
        return;
      }
      setThumbnail(selectedFile);
    } else {
      toast.error("Please select a valid image file");
      setThumbnail(null);
      e.target.value = null; // Clear the input field
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    const isUpdated = fbxFile !== null || thumbnail !== null || fileName !== "";

    if (!isUpdated) {
      toast.warning("Nothing to update");
      setLoading(false);
      return;
    }

    const data = new FormData();
    if (fbxFile) data.append("modelFile", fbxFile);
    if (thumbnail) data.append("thumbnail", thumbnail);
    if (fileName !== preData.file_name) data.append("fileName", fileName);

    const res = await updateFurniture(preData.id, data);

    if (res.success) {
      toast.success(res.message);
      navigate("/furniturelist")
    } else {
      toast.error(res.message);
    }
    setFbxFile(null);
    setThumbnail(null);
    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="topic_form">
        <form onSubmit={onSubmitHandler}>
          <div className="row">
            <div className="col-lg-12">
              <div className="row p-3">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="name">Select fbx file</label>
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile02"
                        onChange={fbxFileHandler}
                        accept=".fbx"
                      />
                    </div>
                    <a href={preData?.model_file} target="_blank" rel="noopener noreferrer">
                      {preData?.model_file && extractFileName(preData?.model_file)}
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="name">Select thumbnail</label>
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile02"
                        onChange={thumbnailHandler}
                        accept="image/*"
                      />
                    </div>
                    {preData && preData.thumbnail && <img
                      className="img-fluid ThumbnailImage"
                      src={preData.thumbnail}
                      alt=""
                    />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="name">File Name</label>
                <div className="input-group mb-3">
                  <input
                    value={fileName}
                    type="text"
                    className="form-control"
                    id="inputGroupFile02"
                    onChange={fileNameHandler}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {loading ? (
                <Loader />
              ) : (
                <button type="submit" className="btn btn-primary btn-block mt-4">
                  <span className="spinner-border spinner-border-sm"></span>
                  UPDATE
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditFurnitureForm;

import React, { useState } from "react";
import { uploadFurniture, editModel } from "../../../services/Api_calling";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";

const AddFurnitureForm = () => {
  const navigate = useNavigate();
  const [fbxFile, setFbxFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const maxFileSizeMb = 30; // Max size in MB

  const fbxFileHandler = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.name.endsWith(".fbx")) {
      const fileSizeMB = selectedFile.size / 1024 / 1024; // Convert bytes to MB
      if (fileSizeMB > maxFileSizeMb) {
        toast.error("File size should not exceed 30MB");
        setFbxFile(null);
        e.target.value = null; // Clear the input field
        return;
      }
      setFbxFile(selectedFile);
    } else {
      toast.error("Please select a valid .fbx file");
      setFbxFile(null);
      e.target.value = null; // Clear the input field
    }
  };


  const fileNameHandler = (e) => {
    setFileName(e.target.value);
  }


  const thumbnailHandler = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type.startsWith("image")) {
      const fileSizeMB = selectedFile.size / 1024 / 1024; // Convert bytes to MB
      if (fileSizeMB > maxFileSizeMb) {
        toast.error("Thumbnail size should not exceed 30MB");
        setThumbnail(null);
        e.target.value = null; // Clear the input field
        return;
      }
      setThumbnail(selectedFile);
    } else {
      toast.error("Please select a valid image file");
      setThumbnail(null);
      e.target.value = null; // Clear the input field
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!fbxFile || !thumbnail || fileName === "") {
      toast.warning("All fields required");
      setLoading(false);
      return;
    }

    const data = new FormData();
    data.append("modelFile", fbxFile);
    data.append("thumbnail", thumbnail);
    data.append("fileName", fileName)

    const res = await uploadFurniture(data);

    if (res.success) {
      toast.success(res.message);
      navigate("/furniturelist")
    } else {
      toast.error(res.message);
    }
    setFbxFile(null);
    setThumbnail(null);
    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="topic_form">
        <form onSubmit={onSubmitHandler}>
          <div className="row p-3">
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="name">Select fbx file
                  <span style={{
                    color: 'red'
                  }}>*</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    type="file"
                    className="form-control"
                    id="inputGroupFile02"
                    onChange={fbxFileHandler}
                    accept=".fbx"
                    style={{
                      padding: '20px',
                      border: '2px dashed #ddd',
                      textAlign: 'center',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="name">Select thumbnail
                  <span style={{
                    color: 'red'
                  }}>*</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    type="file"
                    className="form-control"
                    id="inputGroupFile02"
                    onChange={thumbnailHandler}
                    accept="image/*"
                    style={{
                      padding: '20px',
                      border: '2px dashed #ddd',
                      textAlign: 'center',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="name">File Name
                  <span style={{
                    color: 'red'
                  }}>*</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    value={fileName}
                    type="text"
                    className="form-control"
                    id="inputGroupFile02"
                    onChange={fileNameHandler}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              {loading ? (
                <Loader />
              ) : (
                <button type="submit" className="btn btn-primary btn-block mt-4">
                  ADD
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddFurnitureForm;
